import React from 'react'

const Ourorientation = () => {
  return (
    <div className="ahoutus__container grid">
      {/* <div className='aboutus-background'>
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_about1.webp" alt="" className="about-img about1" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_Vector2.webp" alt="" className="about-img about2" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_aboutline.webp" alt="" className="about-img aboutline" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_line2.webp" alt="" className="about-img line2" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_lineabout.webp" alt="" className="about-img lineabout" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_lineabout1.webp" alt="" className="lineabout1" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_lineabout2.webp" alt="" className=" lineabout3" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/Groupaboutus.webp" alt="" className="about-img Groupabout1" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_Groupdotabout.webp" alt="" className="about-img Groupaboutdot" />
        <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_dot2.webp" alt="" className=" lineabout2" />
      </div> */}
      
      <section className="aboutus grid wide">
        
        <div className="row">
          <div className="aboutus__left col l-3 l0-12 c-12">
            <div className="aboutus__left-wrapper">
              <h1>Our Orientation</h1>
              <p>Our desire is to build the</p>
              <p>foundation of the future</p>
              <p>where both the physical</p>
              <p>and virtual worlds are one</p>
            </div>
          </div>
          <div className="aboutus__right l-9 l0-12">
            <div className="row no-gutters">
              <div className="aboutus__right-1 col l-6 c-12 m-12 l0-6 ">
                <div className="aboutus__card aboutus__card-mt col c-12">
                  <img src="https://media.graphassets.com/bjYz71dHRp6971tkcC1d" alt="" className="aboutus__card-img" />
                  <div className="aboutus__card-infor">
                    <span>Liberty</span>
                    <p>The patrons will be in the custody of their own data stored in the cloud.</p>
                  </div>
                </div>
                <div className="aboutus__card col c-12 m-12">
                  <img src="https://media.graphassets.com/cq3463u1TOyVgSoiq50f" alt="" className="aboutus__card-img" />
                  <div className="aboutus__card-infor">
                    <span>Community Driven</span>
                    <p>Our denizens have a genuine stake in the products they utilize. The cohesive psyche of the nest is now unshackled!</p>
                  </div>
                </div>
              </div>
              <div className="aboutus__right-2 col l-6 c-12 m-12 l0-6">
                <div className="aboutus__card aboutus__card-mt col c-12">
                  <img src="https://media.graphassets.com/YWs7YtPBTQmJFa3PQ3xR" alt="" className="aboutus__card-img" />
                  <div className="aboutus__card-infor">
                    <span>Borderless</span>
                    <p>Lean on furthering our targets. Passion is meant for bravo.</p>
                  </div>
                </div>
                <div className="aboutus__card col c-12 m-12">
                  <img src="https://media.graphassets.com/Cs5EtFvhRVWlPSGShRbL" alt="" className="aboutus__card-img" />
                  <div className="aboutus__card-infor">
                    <span>Synthe</span>
                    <p>Insights on the cutting edge fit great when blended in unusual ways.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <img src="https://storage.googleapis.com/laboon-img-storage/laboon/image-Home-OurOrientation/laboon_image-Home-OurOrientation_Groupabout2.webp" alt="" className="Groupabout2" /> */}
    </div>
  )
}

export default Ourorientation
